import React, { useMemo } from "react";
import { useNavigation } from "react-router";

/**
 * Props for the MingleInput component
 * @typedef {Object} MingleInputProps
 */
type MingleInputProps = {
  name?: string; // Input field name
  label?: string; // Label text for the input
  placeHolder?: string; // Placeholder text
  error?: any; // Error message to display
  type?: string; // HTML input type
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void; // Change handler
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void; // Focus handler
  startIcon?: React.ReactNode; // Icon to display at start of input
  endIcon?: React.ReactNode; // Icon to display at end of input
  labelIcon?: React.ReactNode; // Icon to display with label
  value?: string | number; // Input value
  required?: boolean; // Is this field required?
  defaultValue?: string | number; // Default value
  disabled?: boolean; // Is the input disabled?
  classNames?: string; // Additional CSS classes for wrapper
  classNamesInput?: string; // Additional CSS classes for input
  autoFocus?: boolean; // Should input auto-focus?
  autoComplete?: boolean; // Enable/disable autocomplete
  isLarge?: boolean; // Larger input style
  list?: string; // ID of datalist for autocomplete
  min?: string | number; // Minimum value for number inputs
  max?: string | number; // Maximum value for number inputs
  maxLength?: number; // Maximum text length
  inputRef?: React.RefObject<HTMLInputElement>;
};

/**
 * MingleInput - A customizable input component with support for icons, validation, and styling
 *
 * Features:
 * - Label and placeholder support
 * - Error handling and display
 * - Start and end icons
 * - Customizable styling
 * - Form state tracking via useNavigation
 */
export const MingleInput = ({
  name,
  label,
  labelIcon,
  placeHolder,
  required,
  min,
  max,
  maxLength,
  error,
  value,
  defaultValue,
  type = "text", // Default to text input
  disabled = false, // Default to enabled
  onChange = () => {}, // Default no-op handler
  onFocus = () => {}, // Default no-op handler
  startIcon,
  endIcon,
  autoFocus = false, // Default to no autofocus
  isLarge = false, // Default to standard size
  classNames,
  classNamesInput,
  list,
  autoComplete = true, // Default to enabled
  inputRef,
}: MingleInputProps) => {
  // Get current navigation state for form submission handling
  const navigation = useNavigation();

  // Memoize input attributes to prevent unnecessary re-renders
  const inputAttributes = useMemo(() => {
    return {
      id: name,
      name,
      required,
      min,
      max,
      maxLength,
      list,
      // Only include value prop if it's provided (including 0 and empty string)
      ...(value || value === 0 || value === "" ? { value } : {}),
      // Only include defaultValue prop if it's provided (including 0 and empty string)
      ...(defaultValue || defaultValue === 0 || defaultValue === ""
        ? { defaultValue }
        : {}),
      // Disable the input during form submission or if explicitly disabled
      disabled: disabled || navigation.state !== "idle",
      type,
      "aria-invalid": error ? true : false,
      placeholder: placeHolder,
      onChange,
      onFocus,
      // If autoComplete is false, add autoComplete="off"
      ...(autoComplete === false ? { autoComplete: "off" } : {}),
    };
  }, [
    name,
    required,
    min,
    max,
    maxLength,
    list,
    value,
    defaultValue,
    disabled,
    navigation.state,
    type,
    placeHolder,
    onChange,
    onFocus,
    autoComplete,
  ]);

  // Memoize CSS classes for the input element to prevent recalculation on every render
  const inputClasses = useMemo(() => {
    return [
      "input input-bordered w-full  input-secondary ",
      "text-gray-500 dark:text-gray-200",
      "border-gray-300 dark:border-gray-900",
      "focus:border-green-500 focus:outline-offset-0 focus:outline-green-200 focus:outline-4",
      "[color-scheme:light] dark:[color-scheme:dark]",
      "disabled:text-gray-800 dark:disabled:bg-gray-900",
      error ? "border-red-500" : "",
      isLarge ? "lg:font-[700] lg:text-2xl " : "",
      startIcon ? "pl-14" : "pl-6",
      endIcon ? "pr-14" : "",
      classNamesInput || "",
    ]
      .join(" ")
      .trim();
  }, [error, isLarge, startIcon, endIcon, classNamesInput]);

  return (
    <div className={`relative w-full max-w-xl ${classNames || ""}`}>
      {/* Input label */}
      {label && (
        <label
          htmlFor={name}
          className="flex gap-2 text-xs font-medium mb-1 text-gray-800 dark:text-gray-200"
        >
          {labelIcon}
          {label}
        </label>
      )}

      {/* Input field container */}
      <div className="relative">
        {/* The actual input element */}
        <input
          ref={inputRef}
          autoFocus={autoFocus}
          className={inputClasses}
          {...inputAttributes}
        />

        {/* Start icon positioning */}
        {startIcon && (
          <div className="absolute w-10 h-8 top-1.5 left-5 pr-3 flex items-center pointer-events-none">
            {startIcon}
          </div>
        )}

        {/* End icon positioning */}
        {endIcon && (
          <div
            className={`absolute w-10 h-8 top-1.5 -right-1 pr-3 flex items-center`}
          >
            {endIcon}
          </div>
        )}

        {/* Error message display */}
        {error && (
          <label className="label absolute bottom-[-22px] w-full left-0">
            <p className="text-sm font-medium text-red-500 text-center w-full">
              {error}
            </p>
          </label>
        )}
      </div>
    </div>
  );
};
